import path from 'path';
import { contact as pageMeta } from '../data/page_meta.yml';
import PagePost from '../models/PagePost';
import PropTypes from 'prop-types';
import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import {
    Link,
    SEO,
} from '@brandedholdings/react-components';

class Contact extends React.Component {
    getChildContext() {
        return { siteMeta };
    }

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);

        const pageImages = {};
        page.data.pageImages.edges.forEach(({ node }) => pageImages[path.basename(node.absolutePath).split('.')[0]] = node);


        return (
            <PageWrapper>
                <SEO post={ post } />
                <BackgroundImage
                    className="background__gatsby background background__graphic"
                    fluid={pageImages.hero.image.fluid}
                >
                    <div className="layout">
                        <div className="layout-content center">
                            <h1>Contact Us</h1>
                        </div>
                    </div>
                </BackgroundImage>
                <div className="background background__white">
                    <div className="layout">
                        <div className="layout-content">
                            <p>Most of the questions we receive are answered on our <Link to="/faq/">FAQ page</Link>. Please review that page before contacting us.</p>
                            <hr />
                            <h3>
                                Mailing Address
                            </h3>
                            <p>
                                {siteMeta.address.name}
                                <br />
                                {siteMeta.address.street}
                                <br />
                                {siteMeta.address.street2}
                                <br />
                                {siteMeta.address.city}, {siteMeta.address.state} {siteMeta.address.zip}
                            </p>
                            <hr />
                            <h3>
                                Email Address
                            </h3>
                            <p>
                                <a href={ "mailto:" + siteMeta.email }>{ siteMeta.email }</a>
                            </p>
                        </div>
                    </div>
                </div>
            </PageWrapper>
        );
    }
};

Contact.childContextTypes = {
    siteMeta: PropTypes.object
};

export default Contact;

export const query = graphql`
    {
        pageImages: allFile(filter: {absolutePath: {regex: "^static/assets/images/covers/"}}) {
            edges {
                node {
                    id
                    absolutePath
                    image: childImageSharp {
                        fluid(
                            quality: 90, 
                            maxWidth: 2000,
                            traceSVG: { background: "#0ba7e2", color: "#0ba7e2" }
                        ) {
                            # base64
                            tracedSVG
                            aspectRatio
                            src
                            srcSet
                            srcWebp
                            srcSetWebp
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                        }
                    }
                }
            }
        }
    }
`;